import React, { useEffect } from "react";
import styles from "./footer.module.scss";
import { Link, useLocation } from "react-router-dom";

export const Footer: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerContent}>
        <div className={styles.footerContentTop}>
          <Link to="/">
            <span>მთავარი</span>
          </Link>
          <span className={styles.bull}>&bull;</span>
          <Link to={"/contact"}>
            <span>კონტაქტი</span>
          </Link>
          <span className={styles.bull}>&bull;</span>
          <Link to={"/about"}>
            <span>ჩვენს შესახებ</span>
          </Link>
        </div>
        <div className={styles.footerContentBottom}>
          <Link to="/privacy-policy">
            <span>კონფიდენციალურობა</span>
          </Link>
          <span className={styles.bull}>&bull;</span>
          <Link to="/terms-conditions">
            <span>წესები და პირობები</span>
          </Link>
        </div>
        <div className={styles.email}>
          <a href="mailto:info.gaixsna.ge">info@gaixsna.ge</a>
          &nbsp;<span className={styles.bull}>&bull;</span>&nbsp; 
           <a href="tel:+995551094335">+995 551 094 335</a>
          {/* <br /> */}
          {/* <span>თბილისი, ალექსიძის ქუჩა, ნ1.</span> */}
        </div>
        <div className={styles.copyRight}>© 2023 - gaixsna.ge</div>
      </div>
    </div>
  );
};
